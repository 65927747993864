import React, { createRef, useEffect } from 'react'
import useWindowSize from '../../..//hooks/useWindowSize'
import lottie from 'lottie-web'
import animation from '../../../animations/data.json'
import { Link } from 'gatsby'
import './header.css'

const Header = () => {
    const { width } = useWindowSize()
    const animationContainer = createRef()

    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: animation
        })
        return () => anim.destroy() // optional clean up for unmounting
    }, [])

    return (
        <header className="header">
            {width >= 768 && (
                <div className="left-content">
                    <p>
                        Based in Mexico, maneuvering worldwide.
                    </p>
                </div>
            )}
            <Link to="/">
                <div className="logo" ref={animationContainer}>
                </div>
            </Link>
            <nav className="nav">
                <ul>
                    <li><Link to="/about">About</Link></li>
                    <li><Link to="/technology">Technology</Link></li>
                    <li><Link to="/contact">Contact</Link></li>
                </ul>
            </nav>
        </header>
    )
}

export default Header