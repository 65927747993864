import React, { useEffect } from 'react'
import Header from './Header'
import Footer from './Footer'
import './Layout.css'

const Layout = ({ children, animatePointer = false }) => {
    useEffect(() => {
        if (animatePointer) {
            const container = document.querySelector('.pointer-trail-container');

            const handleMouseMove = (e) => {
                const dot = document.createElement('div');
                dot.className = 'dot';
                dot.style.left = `${e.pageX}px`;
                dot.style.top = `${e.pageY}px`;
                container.appendChild(dot);

                setTimeout(() => {
                    dot.style.opacity = '0';
                    setTimeout(() => dot.remove(), 500); // Shorter time before dot is removed
                }, 0); // Immediately start fading out
            };

            document.addEventListener('mousemove', handleMouseMove);

            return () => {
                document.removeEventListener('mousemove', handleMouseMove);
            }
        }
    }, [animatePointer])

    return (
        <>
            <Header />
            <div className="mobile-offset" />
            {children}
            <Footer />
            <div className="pointer-trail-container"></div>
        </>
    )
}

export default Layout