import React from 'react'
import STLogo from '../../../images/Clients/STLogo.png'
import Lala from '../../../images/Clients/lala.png'
import Bquate from '../../../images/Clients/bquatesvg@4x.png'
import Goma from '../../../images/Clients/Goma.webp'
import Childfund from '../../../images/Clients/Childfund.webp'
import Ingezar from '../../../images/Clients/Ingezar.webp'
import Bora from '../../../images/Clients/Bora.webp'
import Datastream from '../../../images/Clients/Datastream.webp'
import Oul from '../../../images/Clients/Oul.png'
import Thor from '../../../images/Clients/Thor.png'
import DirHumana from '../../../images/Clients/DirHumana.png'
import Hadasa from '../../../images/Clients/Hadasa.png'
import Tycoon from '../../../images/Clients/Tycoon.png'
import Endi from '../../../images/Clients/Endi.png'
import Worldpark from '../../../images/Clients/Worldpark.webp'
import Woof from '../../../images/Clients/Woof.webp'
import Imon from '../../../images/Clients/Imon.webp'
import './footer.css'

const Imgs = () => (
    <>
        <img style={{marginLeft: 40}} src={STLogo}/>
        <img style={{height: '90%'}} src={Lala}/>
        <img src={Bquate}/>
        <img src={Goma}/>
        <img style={{height: '80%'}} src={Worldpark}/>
        <img style={{height: '70%'}} src={Woof}/>
        <img src={Imon}/>
        <img src={Childfund}/>
        <img style={{height: '70%'}} src={Datastream}/>
        <img src={DirHumana}/>
        <img style={{height: '70%'}} src={Endi}/>
        <img src={Bora}/>
        <img src={Tycoon}/>
        <img src={Ingezar}/>
        <img src={Oul}/>
    </>
)

const Footer = () => {
    return (
        <footer className="footer">
            <div className="clients">
                <p>Clients our team has worked with</p>
            </div>
                <div className="logos-container">
                    <div className="clients-logos">
                        <Imgs />
                    </div>
                    <div className="clients-logos">
                        <Imgs />
                    </div>
                </div>
        </footer>
    )
}

export default Footer